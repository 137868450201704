import React from "react"
import { Link } from "gatsby"

const Navbar = () => {
    // const [isHovered, setIsHovered] = useState(false)

    // let navClassName = 

    // const handleHover = () => {
    //     setIsHovered(!isHovered)

    // }

    return (
        <header className="text-gray-700 body-font">
            <div className="container md:grid-cols-3rfr grid-rows-2 mx-auto grid p-5 items-center">
                <nav className="flex text-gray-500 justify-center md:order-1 order-2 items-center text-base">
                    <Link
                        activeClassName="text-black"
                        className="mr-5 hover:text-black hover:no-underline"
                        to="/"
                    >
                        Home
                    </Link>
                    <Link
                        activeClassName="text-black"
                        className="mr-5 hover:text-black hover:no-underline"
                        to="/voyage"
                    >
                        Voyage
                    </Link>
                    <Link
                        activeClassName="text-black"
                        className="mr-5 hover:text-black hover:no-underline"
                        to="/blog"
                    >
                        Essays
                    </Link>
                </nav>
                {/* <a className="flex order-first lg:order-none lg:w-1/5 title-font font-medium items-center text-gray-900 lg:items-center lg:justify-center mb-4 md:mb-0"> */}
                <div className="w-full order-1 md:order-2 flex justify-center">
                    <Link
                        to="/"
                        className="ml-3 text-black hover:text-black hover:no-underline text-3xl font-logoFont"
                    >
                        MIHIR GUPTA
                    </Link>
                </div>
                {/* </a> */}
                {/* <div className="lg:w-2/5 inline-flex lg:justify-end ml-5 lg:ml-0">
                    <button className="inline-flex items-center bg-gray-200 border-0 py-1 px-3 focus:outline-none hover:bg-gray-300 rounded text-base mt-4 md:mt-0">
                        Button
                        <svg
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            className="w-4 h-4 ml-1"
                            viewBox="0 0 24 24"
                        >
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                    </button>
                </div> */}
            </div>
        </header>
    )
}

export default Navbar
