import React from "react"
import { Link } from "gatsby"

import Navbar from "./Navbar"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1 className="text-4xl">
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        // maxWidth: rhythm(24),
        // padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <Navbar />
      {title && <header>{header}</header>}
      <main>{children}</main>
      <footer className="flex justify-center my-10">
        © {new Date().getFullYear()}, Built with 🖤 by {` `}
        <span>
          <a href="https://www.gatsbyjs.org">{` `}Mihir</a>
        </span>
      </footer>
    </div>
  )
}

export default Layout
